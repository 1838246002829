.card-number-input {
  position: relative;
}
.card-number-input input {
  padding-left: 16%;
}

.card-number-input .fa-credit-card {
  position: absolute;
  top: 17%;
  left: 4%;
  font-size: 162%;
  color: #ccc;
}
.card-number-input .fa-check {
  position: absolute;
  left: 90%;
  top: 26%;
  color: #ccc;
}
