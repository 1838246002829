.cookiesAlert {
  position: fixed;
  width: 100%;
  top: 87%;
  background: #4d4d4d;
  height: 13%;
}
.cookiesAlert p {
  color: white;
  font-size: 0.8rem;
}
.cookiesAlert a {
  font-size: 0.8rem;
  color: white;
  text-decoration: underline;
}
.cookiesAlert a:hover {
  color: white;
}

@media screen and (max-width: 768px) {
  .cookiesAlert {
    top: 62%;
    height: 38%;
  }
}

/*@media screen and (min-width: 400px) and (max-width: 768px) {
  .cookiesAlert {
    top: 80%;
    height: 20%;
  }
}*/
