* {
  margin: 0px;
  padding: 0px;
  /* font-family: "Roboto", sans-serif; */
  color: #484848;
  font-family: "Poppins", sans-serif;
  font-size: 13pt;
  /* line-height: 26px !important; */

  font-weight: 400;
}
body {
  line-height: 26px !important;
}
h1 {
  font-size: 2rem !important;
}
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap"); */

.main-home-img img {
  width: 100%;
  height: 38rem;
  object-fit: cover;
}

.contant-section {
  width: 80%;
  height: max-content;
  display: block;
  margin: 0px auto;
  position: relative;
  top: -50px;
  background: white;
}
.service {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
}

.welcome-section {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  padding: 30px;
}
.position-r {
  position: relative;
}
.currncey-input {
  position: absolute;
  top: 58%;
  left: 83%;
}
.welcome-section h1 {
  /*border-bottom: 1px solid silver;*/
  width: 450px;
  height: auto;
  margin: 0px auto 10px auto;
  text-align: center;
  font-size: 1.6rem !important;
  font-weight: 700;
}
.services-section h1 {
  /*border-bottom: 1px solid silver;*/
  width: 200px;
  height: auto;
  margin: 0px auto 10px auto;
  text-align: center;
  font-size: 1.6rem !important;
  font-weight: 700;
}

.service-card {
  margin: 5px auto;
  width: 270px;
  text-align: center;
  padding: 12px;
}
.services-section {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  padding: 30px;
}

.services-section i {
  font-size: 3rem;  
}

.contact-us-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}
.contact-us-section h1 {
  /*border-bottom: 1px solid silver;*/
  margin-bottom: 10px;
  font-size: 1.6rem !important;
  width: 200px;
  font-weight: 700;
}

.section-footer {
  background: #8b8989cc;
  height: 80px;
  width: 100%;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 18px;
  bottom: 0;
}
.section-footer ul li {
  color: white;
  margin-right: 5px;
}
.section-footer ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
}
.section-footer a,
a:hover {
  text-decoration: none;
  color: white;
  font-size: 10pt;
  font-weight:700;
}

/* --------------------- */
.nav-link {
  transition: all 0.5s ease;
  font-size: 15px !important;
  color: #007bff !important;
}
.nav-link.nav-link-item {
  color: rgb(0 0 0 / 65%) !important;
}


/* .navbar-nav .nav-link:hover {
  background: black;
  color: white !important;
 
  margin: 0px;
} */

.border-r-0 {
  border-radius: 0px !important;
}
.btn-main {
  background: #4ba6ff;
  border-color: #4ba6ff;
  padding: 8px 30px;
  font-weight: 600;
  transition: all 0.5s ease;
}
.btn-main:hover {
  background: #00f;
  text-decoration: none;
  box-shadow: 0 4px 7px 2px #7f7f7f !important;
}
.main-select-input {
  margin-right: 3px;
  width: 50%;
  padding: 9px 5px !important;
  border-radius: 2px;
  outline: none;
}
a,
button {
  cursor: pointer !important;
}
.navbar-nav .dropdown-item {
  font-size: 15px;
}
@keyframes navBarLinksWidth {
  from {
    width: 0%;
  }
  to {
    width: 50%;
  }
}
/* @media screen and (max-width: 768px) {
  .navbar-nav a {
    font-size: 11px !important;
  }
} */
@media screen and (width: 768px) {
  .navbar-nav a {
    font-size: 11px !important;
  }
  .navbar-nav select {
    font-size: 15px !important;
  }
  /* .navbar-nav select option {font-size: 15px !;} */
}
@media screen and (max-width: 425px) {
  .contant-section {
    width: 95%;
    padding: 4px 0px;
  }
  .contant-section select {
    font-size: 11pt;
  }
}
@media screen and (max-width: 764px) {
  .main-home-img img{
    height: 20rem;
  }
  
  .welcome-section h1 {
    width: 200px;
    font-size: 1.5rem;
  }

  .services-section h1 {
    font-size: 1.5rem;
  }
  .contact-us-section h1 {
    font-size: 1.5rem;
  }
  .select-currency-navbar {
    margin-left: -25px !important;
    width: 108px !important;
  }
  .navbar {
    direction: rtl !important;
  }
  .navbar a {
    direction: ltr !important;
  }

  /* .navbar-nav::after{
    content:"";
    position: fixed;
    background: #00000075;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 1;
    height: 100%;
    padding: 15px 0px 0px 0px;
  } */
  .navbar-nav {
    background: white;
    width: 50%;
    position: fixed;
    left: 0px;
    height: 100%;
    top: 0px;
    z-index: 6;
    padding: 0px 0px 0px 14px;
    padding-top: 71px !important;
    transition: all ease 0.3s !important;
    animation-name: navBarLinksWidth;
    animation-duration: 0.5s;
  }
  .navbar {
    position: fixed;
    width: 100%;
    z-index: 1;
    transition: .5s ease;
    height: 55px;
    padding-left: 10px !important;
  }
  .navbar-toggler {
    z-index: 7 !important;
  }
  /* .navbar-nav::after {
    content: "";
    display: block;
    width: 100vw;
    position: absolute;
    height: 100%;
    background: #5a555599;
    top: 0px;
    left: 100%;
    z-index: 1;
  } */
}
.navbar-nav a {
  font-weight: 700 !important;
}

.menu-bg {
  display: block;
  width: 100%;
  height: 100%;
  background: #21252954;
  position: fixed;
  z-index: 1;
}

.header-text {
  position: absolute;
  top: 25%;
  left: 5%;
  color: #dbe9f9;
  font-size: 3rem;
  line-height: 72px;
  font-weight: 700;
}

.continueBtn{
  margin-top: -12%;
}

@media screen and (max-width:764px){
  .header-text {
    top: 13%;
    left: 2%;
    font-size:1.2rem;
    line-height: 32px;
  }

  #logo{
    height: 48px;
  }

  .continueBtn{
    margin-top: 0;
  }
  
}

.border-success{
  border-color: #4ba6ff !important;
  margin-top: 36px;
}
.bg-success {
  background-color: #4ba6ff !important;
}

.disableUsersBtn{
  color: red;
  font-size: 15px !important;
  border: 1px solid red;
}

.enableUsersBtn{
  color: green;
  font-size: 15px !important;
  border: 1px solid green;
}